import React, { Component } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import Tag from '../components/tag'
import TextSection from '../components/textSection'
import phoneIcon from '../images/phone-icon.svg'
import calendarIcon from '../images/calendar-icon.svg'
import { URL } from '../utils/utils'
import subwayIcon from '../images/subway.svg'
import carIcon from '../images/car.svg'
import busIcon from '../images/bus.svg'
import { Link } from 'gatsby'
import FloatingButtons from '../components/FloatingButtons'
import publicTransportIcon from '../images/public-transport-icon.svg'
import navigationIcon from '../images/navigation-icon.svg'
import { SectionHeader } from "../components/HelperComponents"
import shuttleBusFile from '../files/shuttle-bus.pdf'


const ContactWrapper = styled.div`
  background-color: ${props => props.background ? props.background : 'initial'};
`;

const StyledWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 126px;
  padding-bottom: 120px;

  @media(max-width: 1184px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      "aboutSection"
      "formWrapper"
      "map";
    grid-gap: 24px;
    padding: 40px 2.5%;
  }
`;

const ResponsiveHeader = styled.div`
  display: none;

  @media(max-width: 1184px) {
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-bottom: 25px;
  }
`;

const ContactInformationWrapper = styled.div`
  display: flex;
  flex-flow: column;

  @media(max-width: 1184px) {
    align-items: center;
  }
`;

const TextSectionWrapper = styled.div`
  margin-bottom: 62px;

  @media(max-width: 1184px) {
    display: none;
  }
`;

const FormWrapper = styled.div`
  padding: 68px;
  background-color: #0f2067;
  border-radius: 5px;

  @media(max-width: 1184px) {
    grid-area: formWrapper;
    // background-color: #203bbc;
    border-radius: 10px;
    padding: 15px;
  }
`;

const Inputs = styled.div``;

const Input = styled.input`
  padding: 13px 20px;
  width: 100%;
  margin-bottom: 20px;
  border-radius: 5px;
  border: none;
  font-size: 16px;

  @media(max-width: 1184px) {
    padding: 11px 16px;
    font-size: 11px;
  }
`;

const Textarea = styled(Input)`
  height: 250px;
  resize: vertical;
`

const SubmitButton = styled.button`
  width: 100%;
  padding: 13px 20px;
  background-color: #203bbc;
  border-radius: 5px;
  cursor: pointer;
  color: #fff;
  font-size: 16px;

  @media(max-width: 1184px) {
    border: 1px solid #fff;
    width: auto;
    padding: 11px 40px;
  }
`;

const AboutSection = styled.div`
  p span {
    background-color: #fff;
    border-radius: 5px;
    padding: 4px;
    color: rgba(0, 0, 0, 1);
  }

  .shuttlebus-link {
    margin-top: 10px;

    // @media(max-width: 1184px) {
    //   font-size: 12px;
    // }
  }

  @media(max-width: 1184px) {
    grid-area: aboutSection;
  }
`;

const PhoneButton = styled.button`
  min-width: 166px;
  padding: 13px 10px 13px 30px;
  border-radius: 5px;
  text-align: left;
  margin: 51px 0 63px;
  font-size: 16px;
  color: #fff;
  background-color: #ff413d;

  .desktop {
    display: flex;
    align-items: center;
  }

  .mobile {
    display: none;
  }

  @media(max-width: 1184px) {
    margin: 30px auto;
    padding: 11px 10px 11px 30px;
    font-size: 11px;
    min-width: 140px;
    display: flex;

    .mobile {
      display: flex;
      align-items: center;
    }

    .desktop {
      display: none;
    }
  }
`;

const PhoneImage = styled.img`
  background-color: #fff;
  padding: 8px;
  margin-left: 28px;
  border-radius: 5px;
  width: 33px;
  height: 33px;

  @media(max-width: 1184px) {
    width: 23px;
    height: 23px;
    padding: 6px;
  }
`;


const CalendarButtonWrapper = styled.div`
  display: inline-block;
  margin-left: 30px;

  @media(max-width: 1184px) {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-left: 0;
  }
`;

const CalendarButton = styled.button`
  min-width: 166px;
  padding: 13px 10px 13px 30px;
  border-radius: 5px;
  text-align: left;
  margin: 51px 0 63px;
  font-size: 16px;
  color: #fff;
  background-color: #1357df;
  display: inline-flex;
  align-items: center;

  @media(max-width: 1184px) {
    margin: 30px auto;
    margin-top: 0;
    padding: 11px 10px 11px 30px;
    font-size: 11px;
    min-width: 140px;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 33px 0;

  @media(max-width: 1184px) {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  border-radius: 5px;
  font-size: 16px;
  margin-right: 30px;
  background-color: transparent;
  border: ${props => props.checked ? '1px solid #fff' : 'none'};

  img {
    background-color: transparent;
    border-radius: 5px;
    padding: 8px;
  }

  @media(max-width: 1184px) {
    padding: 11px 13px;
    font-size: 11px;
    color: ${props => props.checked ? '#0b164a' : '#fff'};
    margin: 0 0 8px;
    min-width: initial;

    img {
      width: 32px;
      height: 32px;
      padding: 6px;
    }
  }
`;

const Map = styled.div`
  grid-column: span 2;
  height: 301px;
  background-color: #0f2067;
  border-radius: 5px;

  @media(max-width: 1184px) {
    grid-area: map;
  }
`;

const DriveTips = styled.div`
  // min-height: 50px;

  @media(min-width: 475px) {
    // min-height: 80px;
  }
  @media(min-width: 360px) {
    // min-height: 100px;
  }
`;

const NavigationLink = styled.a`
  display: flex;
  align-items: center;
  
  img {
    margin-right: 10px;
  }

  @media(max-width: 1184px) {
    img {
      width: 20px;
      height: 20px;
    }
  }
`;


class ContactSection extends Component {
  state = {
    name: '',
    from: '',
    phone: '',
    message: '',
    driveTipOption: 'car',
    showNumber: false
  }

  handleSubmit = event => {
    event.preventDefault();
    const formData = new FormData(event.target);
    let jsonObject = {};

    for (const [key, value] of formData.entries()) {
      jsonObject[key] = value;
    }

    jsonObject = {
      ...jsonObject,
      doctor: this.props.mailTitle || null,
    }

    axios.post(`${URL}/api/mail`, jsonObject, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    })
      .then(() => {
        this.setState({
          name: '',
          from: '',
          phone: '',
          message: ''
        });
      })
      .catch(err => console.log(err));
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  }

  changeDriveTipOption = option => {
    this.setState({driveTipOption: option});
  }

  showNumber = () => {
    this.setState({ showNumber: true });
  }

  render() {
    return (
      <React.Fragment>
        <ContactWrapper background={this.props.background} >
          <span id="kontakt" className="anchor"></span>
          <div id="contact" className="scrollSection">
            {/* <TextSectionWrapper>
              <Tag tag={this.props.title || "Kontakt"} />
              <TextSection
                title="Auskünfte & Sprechstundentermine" />
            </TextSectionWrapper> */}

            <SectionHeader title="Auskünfte & Sprechstundentermine" />

            <StyledWrapper>
              <FormWrapper>
                <form onSubmit={this.handleSubmit}>
                  <Inputs>
                    <Input
                      type="text"
                      name="name"
                      placeholder="Name"
                      value={this.state.name}
                      onChange={event => this.handleChange(event)}
                      required />
                    <Input
                      type="text"
                      name="from"
                      placeholder="Email"
                      value={this.state.from}
                      onChange={event => this.handleChange(event)}
                      required />
                    <Input
                      type="text"
                      name="phone"
                      placeholder="Telefon (optional)"
                      value={this.state.phone}
                      onChange={event => this.handleChange(event)} />
                    <Textarea
                      as="textarea"
                      name="message"
                      placeholder="Ihre Nachricht"
                      value={this.state.message}
                      onChange={event => this.handleChange(event)}
                      required />
                  </Inputs>
                  <p>Diese Nachricht wird über einen nicht gesicherten Mailserver versendet.</p>
                  <p>Wir bitten Sie vertrauliche Daten an <a href="mailto:renegabrieli.agzentrum@hin.ch">renegabrieli.agzentrum@hin.ch</a> zu senden.</p>
                  <p><br /></p>
                  <SubmitButton>Senden</SubmitButton>
                </form>
              </FormWrapper>
              <AboutSection>
                {/* <ResponsiveHeader>
                  <p>Auskünfte & Sprechstundentermine</p>
                  <h2>Kontakt</h2>
                </ResponsiveHeader> */}
                <ContactInformationWrapper>
                  <p>Aorten- und GefässZentrum</p>
                  <p>Klinik Hirslanden</p>
                  <p>Witellikerstrasse 40</p>
                  <p>CH-8032 Zürich</p>
                </ContactInformationWrapper>
                <PhoneButton onClick={this.showNumber}>
                  {!this.state.showNumber &&
                    <>
                      <span className="desktop">Anrufen<PhoneImage src={phoneIcon} /></span>
                      <span className="mobile">Anrufen<PhoneImage src={phoneIcon} /></span>
                    </>
                  }
                  {this.state.showNumber &&
                    <>
                      <span className="desktop">
                        Während der Geschäftszeiten: &nbsp; +41 44 387 29 29<br />
                        Ausserhalb der Geschäftszeiten: &nbsp; +41 44 387 21 11
                      </span>
                      <span className="mobile">
                        <a href={`tel: +41 44 387 29 29`}>Während der Geschäftszeiten: &nbsp; +41 44 387 29 29</a><br />
                        <a href={`tel: +41 44 387 21 11`}>Ausserhalb der Geschäftszeiten: &nbsp; +41 44 387 21 11</a>
                      </span>
                    </>
                  }
                </PhoneButton>
                <CalendarButtonWrapper id="booking-button">
                  <Link to={`/kalender/?doctor=${this.props.doctor || ''}`}>
                    <CalendarButton>
                      Termin buchen
                      <PhoneImage src={calendarIcon} />
                    </CalendarButton>
                  </Link>
                </CalendarButtonWrapper>

                <p>Unsere Praxis befindet sich in der Klinik Hirslanden Trakt N. Benutzen Sie beim Haupteingang den Aufgang rechts zur 3. Etage.</p>
                <ButtonsWrapper>
                  <Button
                    checked={this.state.driveTipOption === 'car'}
                    onClick={() => this.changeDriveTipOption('car')}>
                    <img src={carIcon} alt="car" />
                  </Button>
                  <Button
                    checked={this.state.driveTipOption === 'bus'}
                    onClick={() => this.changeDriveTipOption('bus')}>
                    <img src={subwayIcon} alt="subway" />
                  </Button>
                </ButtonsWrapper>
                {this.state.driveTipOption === 'car'
                  ? <DriveTips>
                    <p>Unseren Besuchern steht eine Tiefgarage (gebührenpflichtig)  zur Verfügung.</p>
                    <p><NavigationLink href="https://goo.gl/maps/DUcaW6kxg6k" target="_blank" rel="noopener noreferrer"><img src={navigationIcon} alt="navigation" /> Anfahrt</NavigationLink></p>
                  </DriveTips>
                  : <DriveTips>
                    <p>Tram <span>Nr. 11</span> ab Zürich Hauptbahnhof oder Zürich Stadelhofen bis Haltestelle Balgrist.</p>
                    <p>Forchbahn <span>S18</span> ab Bahnhof Stadelhofen bis Haltestelle Balgrist.</p>
                    <p><NavigationLink href="https://online.fahrplan.zvv.ch" target="_blank" rel="noopener noreferrer"><img src={publicTransportIcon} alt="navigation" /> Fahrplan</NavigationLink></p>
                    <p><NavigationLink href={shuttleBusFile} className="shuttlebus-link" title="shuttle bus link"><img src={busIcon} alt="car" />Klinik Shuttlebus</NavigationLink></p>
                  </DriveTips>}
              </AboutSection>
              <Map id="map" />
            </StyledWrapper>
          </div>
        </ContactWrapper>

        <FloatingButtons doctor={this.props.doctor || ''} />
      </React.Fragment>
    )
  }
}

export default ContactSection;
