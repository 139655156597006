import React from 'react';
import styled from 'styled-components';

const SectionHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 24px 0;

  @media(max-width: 1184px) {
    padding: ${props => props.noPadding ? '0' : '0 2.5%'};
  }
`;

const HederText = styled.h2`
  font-size: 32px;

  @media(max-width: 1184px) {
    font-size: 24px;
  }
`;

const Line = styled.div`
  height: 1px;
  width: 194px;
  background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
  margin-right: 20px;

  @media(max-width: 1184px) {
    width: 20%;
    max-width: 194px;
  }
`;


export const SectionHeader = props => (
  <SectionHeaderWrapper noPadding={props.noPadding}>
    <Line/>
    <HederText>{props.title}</HederText>
  </SectionHeaderWrapper>
);