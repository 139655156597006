import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import phoneIcon from '../images/phone-icon-1.svg';
import calendarIcon from '../images/calendar-icon-1.svg';


const ButtonsWrapper = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 100;
  transition: all 0.3s;
`;

const Button = styled.button`
  margin-left: 20px;
  display: inline-flex;
  align-items: center;
  padding: 7px 7px 7px 15px;
  border-radius: 5px;
  outline: none;
  color: #fff;
  background: ${props => props.background};
  font-size: 12px;
  box-shadow: 0 0 10px rgba(3, 6, 21, 0.37);
  transition: all 0.3s;

  &:hover {
    background: ${props => props.backgroundHovered};
    transition: all 0.3s;
  }

  .desktop {
    display: inline-flex;
    text-align: left;
  }

  .mobile {
    display: none;
  }

  @media(max-width: 1184px) {
    margin-bottom: 15px;
    .desktop {
      display: none;
    }

    .mobile {
      display: inline-flex;
      text-align: left;
      flex-direction: column;
      a {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }
`;

const ButtonIcon = styled.img`
  background: #fff;
  border-radius: 3px;
  width: 22px;
  height: 22px;
  padding: 5px;
  margin-left: 15px;
`;

class FloatingButtons extends Component {

  state = {
    numberRevealed: false,
  }

  componentDidMount() {
    window.addEventListener("scroll", this.watchContactButtons)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.watchContactButtons)
  }

  watchContactButtons() {
    const bookingButton = document.querySelector('#booking-button');
	  const bookingButtonRect = bookingButton.getBoundingClientRect();
    const buttons = document.querySelector('#buttons-wrapper');

    if ((bookingButtonRect.top + 50) < window.innerHeight) {
      buttons.style.opacity = '0';
    } else {
      buttons.style.opacity = '1';
    }
  }

  showNumber = () => {
    this.setState({
      numberRevealed: true
    });
  }

  render(){
    return (
      <ButtonsWrapper id="buttons-wrapper">
        <Button background='#ff413d' backgroundHovered='#e33532' onClick={this.showNumber}>
          {!this.state.numberRevealed &&
            <>
              <span className="desktop">Anrufen</span>
              <span className="mobile">Anrufen</span>
              <ButtonIcon src={phoneIcon} alt="phone" />
            </>
          }
          {this.state.numberRevealed &&
            <>
              <span className="desktop">
                Während der Geschäftszeiten: +41 44 387 29 29<br />
                Ausserhalb der Geschäftszeiten: +41 44 387 21 11
              </span>
              <span className="mobile">
                <a href={`tel: +41 44 387 29 29`}>Während der Geschäftszeiten: +41 44 387 29 29 <ButtonIcon src={phoneIcon} alt="phone" /></a><br />
                <a href={`tel: +41 44 387 21 11`}>Ausserhalb der Geschäftszeiten: +41 44 387 21 11 <ButtonIcon src={phoneIcon} alt="phone" /></a>
              </span>
            </>
          }
        </Button>

        <Link to={`/kalender/?doctor=${this.props.doctor || ''}`}>
          <Button background='#1357df' backgroundHovered='#1D39C0'>
            Termin Buchen
            <ButtonIcon src={calendarIcon} alt="phone" />
          </Button>
        </Link>
      </ButtonsWrapper>
    )
  }
}

export default FloatingButtons;
