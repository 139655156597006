import React from 'react';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  @media(max-width: 1184px) {
    display: none;
  }
`;

const Line = styled.div`
  height: 1px;
  width: 194px;
  background: linear-gradient(90deg, rgba(255,255,255,0) 0%, ${props => props.color ? props.color : 'rgba(255,255,255,1)'} 100%);
  margin-right: 20px;
`;

const Tag = styled.p`
  color: ${props => props.color ? props.color : '#fff'};
  line-height: initial;
`;

const tag = props => (
  <StyledWrapper>
    <Line color={props.color} />
    <Tag color={props.color}>{props.tag}</Tag>
  </StyledWrapper>
);

export default tag;