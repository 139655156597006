import React from 'react';
import styled from 'styled-components';

const StyledWrapper = styled.div``;

const H1 = styled.h1`
  font-size: 32px;
  line-height: 1.2;
  margin-bottom: 40px;
  color: ${props => props.color ? props.color : '#fff'};

  @media(max-width: 1184px) {
    display: none;
  }
`;

const Text = styled.div`
  font-family: 'Poppins', sans-serif;
  margin: 0;
  font-size: 16px;
  line-height: 35px;
  color: ${props => props.color ? props.color : '#c8d2ff'};

  .cv-content-wrapper {
    p {
      color: #3c3c3c;
    }
  }
  
  .content-wrapper {
    p, ul, li {
      color: #3c3c3c;
    }
    
    li {
      font-size: 16px;
    }
  }
  
  @media(max-width: 1184px) {
    font-size: 12px;
    line-height: 25px;
  }
`;

const TextSection = props => (
  <StyledWrapper>
    <H1 color={props.headersColor}>{props.title}</H1>
    {props.text && <Text color={props.textColor}>{props.text}</Text>}
  </StyledWrapper>
);

export default TextSection;